// base url
export var nonPciApiHost = 'https://'+location.host+"";

export const employeeIDPath = 'employeeidlookup/v1/employeeid';

var windowHostname = location.host;
if (windowHostname.includes('priorassociate')) {
  // priorassociate.bbwcorp.com
  // for both service and ui on same VM:
  nonPciApiHost = 'http://'+location.host+":8081";
} else if (windowHostname.includes('test') || windowHostname.includes('perf') || windowHostname.includes('localhost')) {
  //nonPciApiHost = 'https://api-qa.bbw.com';
  // config for local
  // nonPciApiHost = 'http://localhost:8081'
}
