import axios from 'axios';
import { employeeIDPath } from '../config';

export default {
  getEmployeeID(DOB, SSN) {
    return axios.post(employeeIDPath, {
      ssn: SSN,
      dob: DOB,
    });
  },
};
